import { useContext } from 'react';
import { Button, WindmillContext } from '@windmill/react-ui';

export default function TheHeader() {
  const { mode, toggleMode } = useContext(WindmillContext);

  return (
    <header className="py-4">
      <div className="flex container justify-between">
        <a href="/" className="flex items-center no-underline">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 385.4 385.4"
            className="text-blue-700 fill-current mr-4"
          >
            <path d="M189 331.3l-.2-107.5L96.3 170 3.9 223.7l.3 108.2 92 53.5 92.8-54.1zm-83.3 27l.1-75.2 64.3-37.6.2 75-64.6 37.8zm-9.4-166.7l64.6 37.6L96.5 267 31.9 229l64.4-37.5zm-73.6 53.8L87.1 283l-.1 75.4-64.1-37.2-.2-75.8zM288.7 170l-92.3 53.7.3 108.2 92 53.5 92.8-54.1-.3-107.5-92.5-53.9zm0 21.6l64.6 37.6L289 267 224.3 229l64.4-37.4zm-73.5 53.8l64.4 37.6-.2 75.4-64-37.2-.2-75.8zm83 112.9l.1-75.2 64.3-37.6.1 75-64.6 37.8zM285.2 53.9L192.7 0l-92.3 53.7.2 108.3 92.1 53.5 92.7-54.1-.2-107.5zm-92.5-32.3l64.6 37.7L193 96.9l-64.6-37.8 64.4-37.5zm-73.6 53.8l64.5 37.7-.2 75.3-64.1-37.2-.2-75.8zm83 113l.2-75.3 64.3-37.6.1 75.1-64.6 37.7z" />
          </svg>
          <h1 className="text-2xl text-blue-700">NURILYAS</h1>
        </a>

        <Button
          layout="link"
          onClick={toggleMode}
          aria-label="Toggle dark mode"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
            className="fill-current"
          >
            {mode === 'dark' ? (
              <path d="M12,9c1.65,0,3,1.35,3,3s-1.35,3-3,3s-3-1.35-3-3S10.35,9,12,9 M12,7c-2.76,0-5,2.24-5,5s2.24,5,5,5s5-2.24,5-5 S14.76,7,12,7L12,7z M2,13l2,0c0.55,0,1-0.45,1-1s-0.45-1-1-1l-2,0c-0.55,0-1,0.45-1,1S1.45,13,2,13z M20,13l2,0c0.55,0,1-0.45,1-1 s-0.45-1-1-1l-2,0c-0.55,0-1,0.45-1,1S19.45,13,20,13z M11,2v2c0,0.55,0.45,1,1,1s1-0.45,1-1V2c0-0.55-0.45-1-1-1S11,1.45,11,2z M11,20v2c0,0.55,0.45,1,1,1s1-0.45,1-1v-2c0-0.55-0.45-1-1-1C11.45,19,11,19.45,11,20z M5.99,4.58c-0.39-0.39-1.03-0.39-1.41,0 c-0.39,0.39-0.39,1.03,0,1.41l1.06,1.06c0.39,0.39,1.03,0.39,1.41,0s0.39-1.03,0-1.41L5.99,4.58z M18.36,16.95 c-0.39-0.39-1.03-0.39-1.41,0c-0.39,0.39-0.39,1.03,0,1.41l1.06,1.06c0.39,0.39,1.03,0.39,1.41,0c0.39-0.39,0.39-1.03,0-1.41 L18.36,16.95z M19.42,5.99c0.39-0.39,0.39-1.03,0-1.41c-0.39-0.39-1.03-0.39-1.41,0l-1.06,1.06c-0.39,0.39-0.39,1.03,0,1.41 s1.03,0.39,1.41,0L19.42,5.99z M7.05,18.36c0.39-0.39,0.39-1.03,0-1.41c-0.39-0.39-1.03-0.39-1.41,0l-1.06,1.06 c-0.39,0.39-0.39,1.03,0,1.41s1.03,0.39,1.41,0L7.05,18.36z" />
            ) : (
              <path d="M9.37,5.51C9.19,6.15,9.1,6.82,9.1,7.5c0,4.08,3.32,7.4,7.4,7.4c0.68,0,1.35-0.09,1.99-0.27C17.45,17.19,14.93,19,12,19 c-3.86,0-7-3.14-7-7C5,9.07,6.81,6.55,9.37,5.51z M12,3c-4.97,0-9,4.03-9,9s4.03,9,9,9s9-4.03,9-9c0-0.46-0.04-0.92-0.1-1.36 c-0.98,1.37-2.58,2.26-4.4,2.26c-2.98,0-5.4-2.42-5.4-5.4c0-1.81,0.89-3.42,2.26-4.4C12.92,3.04,12.46,3,12,3L12,3z" />
            )}
          </svg>
        </Button>
      </div>
    </header>
  );
}
