import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { capitalCase } from 'change-case';
import { Link } from 'react-router-dom';
import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@windmill/react-ui';
import EmptyState from '../components/EmptyState';
import emptyIllustration from '../assets/empty.svg';
import { truncateHash } from '../helpers/hash';
import { endpoints } from '../config/api';

const BlockDetails: React.FC = () => {
  let { hash } = useParams<{ hash: string }>();

  const { data, error } = useSWR(`${endpoints.blocks}/${hash}`);

  const isError = Boolean(error);
  const isLoading = !error && !data;

  let mainContent: React.ReactElement | null = null;

  if (isError) {
    mainContent = (
      <EmptyState
        illustrationSrc={emptyIllustration}
        title="Sorry! We couldn't find the block you're looking for"
        message="Seems like the block doesn't exist or we are doing something wrong."
        actionLabel="Find another block"
        actionRoute="/"
      />
    );
  } else if (isLoading) {
    mainContent = (
      <div data-testid="loadingSkeleton" className="flex flex-col mt-12">
        {[...Array(10)].map((_, index) => (
          <div key={index} className="animate-pulse flex flex-1 space-x-6 p-3">
            <div className="w-1/4 bg-cool-gray-200 h-6 rounded-md dark:bg-gray-700"></div>
            <div className="w-full bg-cool-gray-200 h-6 rounded-md dark:bg-gray-700"></div>
          </div>
        ))}
      </div>
    );
  } else {
    mainContent = (
      <TableContainer className="mt-8">
        <Table>
          <TableBody>
            {Object.entries(data).map(([key, value]) =>
              ['string', 'number', 'boolean'].includes(typeof value) ? (
                <TableRow key={key}>
                  <TableCell className="text-cool-gray-500">
                    <span>{capitalCase(key)}</span>
                  </TableCell>
                  <TableCell>
                    <span>{String(value)}</span>
                  </TableCell>
                </TableRow>
              ) : null,
            )}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  return (
    <section>
      <nav>
        <ol className="list-reset py-1 flex">
          <li>
            <Link to="/">Blocks List</Link>
          </li>
          <svg className="h-5 w-5 mt-1 transform -rotate-90">
            <path
              className="fill-current"
              d="M15.88 9.29L12 13.17 8.12 9.29c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41l4.59 4.59c.39.39 1.02.39 1.41 0l4.59-4.59c.39-.39.39-1.02 0-1.41-.39-.38-1.03-.39-1.42 0z"
            ></path>
          </svg>
          <li className="px-2 font-semibold">
            Blocks: <span>{truncateHash(hash)}</span>
          </li>
        </ol>
      </nav>
      {mainContent}
    </section>
  );
};

export default BlockDetails;
