const customTheme = {
  button: {
    primary: {
      base: 'text-white bg-blue-600 border border-transparent',
      active:
        'active:bg-blue-600 hover:bg-blue-700 focus:ring focus:ring-blue-300',
    },
    input: {
      active:
        'focus:border-blue-400 border-gray-300 dark:border-gray-600 focus:ring focus:ring-blue-300 dark:focus:border-gray-600 dark:focus:ring-gray-300 dark:bg-gray-700',
    },
  },
};

export default customTheme;
