import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import BlocksList from './views/BlocksList';
import BlockDetails from './views/BlockDetails';
import NotFound from './views/NotFound';
import TheHeader from './components/TheHeader';
import TheFooter from './components/TheFooter';

function App() {
  return (
    <>
      <TheHeader />
      <main className="mb-auto mt-10 container">
        <Router>
          <Switch>
            <Route exact path="/" component={BlocksList} />
            <Route exact path="/:hash" component={BlockDetails} />
            <Route component={NotFound} />
          </Switch>
        </Router>
      </main>
      <TheFooter />
    </>
  );
}

export default App;
