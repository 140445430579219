import ReactDOM from 'react-dom';
import TheSWRConfig from './components/TheSWRConfig';
import { Windmill } from '@windmill/react-ui';
import customTheme from './customTheme.js';
import './styles/index.css';
import App from './App';

ReactDOM.render(
  <TheSWRConfig>
    <Windmill theme={customTheme} usePreferences>
      <App />
    </Windmill>
  </TheSWRConfig>,
  document.getElementById('root'),
);
