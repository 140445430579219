import React from 'react';
import { SWRConfig } from 'swr';
import { SWRConfiguration } from 'swr/dist/types';

interface Props {
  noCache?: boolean;
}

interface Options extends SWRConfiguration {
  provider?: any;
}

const MySWRConfig: React.FC<Props> = ({ children, noCache }) => {
  const options: Options = {
    fetcher: (resource, init) =>
      fetch(resource, init).then((res) => res.json()),
  };

  if (noCache) {
    options.provider = () => new Map();
  }

  return <SWRConfig value={options}>{children}</SWRConfig>;
};

export default MySWRConfig;
