export function getTodayDate(): string {
  return getISODateOnly(new Date());
}

export function getYesterdayDate(): string {
  let date = new Date();
  date.setDate(date.getDate() - 1);
  return getISODateOnly(date);
}

export function getVerboseDay(day: string) {
  if (day === getTodayDate()) return 'Today';
  if (day === getYesterdayDate()) return 'Yesterday';
  return day;
}

function getISODateOnly(date: Date): string {
  return date.toISOString().split('T')[0];
}
