import EmptyState from '../components/EmptyState';
import lostIllustration from '../assets/lost.svg';

export default function NotFound() {
  return (
    <EmptyState
      illustrationSrc={lostIllustration}
      title="Oh! Seems like you're lost 😕"
      message="Here is a link to the best page on internet:"
      actionLabel="Home"
      actionRoute="/"
    />
  );
}
