export default function TheFooter() {
  return (
    <footer className="px-8 py-4 mt-10 bg-cool-gray-100 dark:bg-gray-700">
      <div className="container text-xs leading-5 md:text-sm md:flex items-center justify-between">
        <p>
          Data provided by <a href="https://blockchain.info">Blockchain.info</a>
        </p>
        <p>
          Made with 💙 by <a href="https://github.com/ilyasmez">ilyasmez</a>
        </p>
      </div>
    </footer>
  );
}
