import { useState, useEffect } from 'react';
import useSWR from 'swr';
import { Link } from 'react-router-dom';
import {
  TableContainer,
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  Pagination,
  Input,
  Button,
  Dropdown,
  DropdownItem,
} from '@windmill/react-ui';
import EmptyState from '../components/EmptyState';
import Spinner from '../components/Spinner';
import { getYesterdayDate, getTodayDate, getVerboseDay } from '../helpers/date';
import { truncateHash } from '../helpers/hash';
import errorIllustration from '../assets/error.svg';
import { endpoints } from '../config/api';

export const ChevronIcon: React.FC = (props) => (
  <svg {...props}>
    <path
      className="fill-current"
      d="M15.88 9.29L12 13.17 8.12 9.29c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41l4.59 4.59c.39.39 1.02.39 1.41 0l4.59-4.59c.39-.39.39-1.02 0-1.41-.39-.38-1.03-.39-1.42 0z"
    />
  </svg>
);

export default function BlocksList() {
  const today = getTodayDate();
  const yesterday = getYesterdayDate();

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedDay, setSelectedDay] = useState(today);
  const [page, setPage] = useState(1);
  const [blocks, setBlocks] = useState<Array<any>>([]);
  const [blocksCount, setCountBlocks] = useState(0);

  const { data, error } = useSWR(
    `${endpoints.blocks}?day=${selectedDay}&page=${page}`,
  );

  useEffect(() => {
    if (data) {
      setBlocks(data.blocks);
      setCountBlocks(data.count);
    }
  }, [data]);

  const isError = Boolean(error);
  const isLoading = !error && !data;

  if (isError) {
    return (
      <EmptyState
        illustrationSrc={errorIllustration}
        title="Oops! Something went wrong."
        message="Sorry for the inconvenience, please try again later."
      />
    );
  }

  return (
    <section>
      <div className="flex items-center">
        <p className="mr-2">Results for</p>
        <div className="relative">
          <Button
            layout="outline"
            iconRight={ChevronIcon}
            onClick={() => setIsDropdownOpen(true)}
          >
            {getVerboseDay(selectedDay)}
          </Button>
          <Dropdown
            className="z-10"
            isOpen={isDropdownOpen}
            onClose={() => setIsDropdownOpen(false)}
          >
            <DropdownItem
              onClick={() => setSelectedDay(today)}
              className="px-2 py-3"
            >
              Today
            </DropdownItem>
            <DropdownItem
              onClick={() => setSelectedDay(yesterday)}
              className="px-2 py-3"
            >
              Yesterday
            </DropdownItem>
            <DropdownItem>
              <Input
                type="date"
                value={selectedDay}
                onChange={(e) => setSelectedDay(e.target.value)}
                css={null}
              />
            </DropdownItem>
          </Dropdown>
        </div>
      </div>
      <TableContainer className="mt-6 relative">
        <Table>
          <TableHeader>
            <TableRow>
              <TableCell>Hash</TableCell>
              <TableCell>Time</TableCell>
              <TableCell>Weight</TableCell>
            </TableRow>
          </TableHeader>
          <TableBody
            className={`text-sm transition-all ${isLoading && 'filter blur'}`}
          >
            {blocks.map((block) => (
              <TableRow key={block.hash}>
                <TableCell>
                  <Link to={`/${block.hash}`}>{truncateHash(block.hash)}</Link>
                </TableCell>
                <TableCell>
                  {new Date(block.time).toLocaleTimeString()}
                </TableCell>
                <TableCell>{block.height}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TableFooter>
          <Pagination
            totalResults={blocksCount}
            resultsPerPage={10}
            onChange={(value) => {
              setPage(value);
            }}
            label="Table navigation"
          />
        </TableFooter>
        {isLoading && <Spinner />}
      </TableContainer>
    </section>
  );
}
