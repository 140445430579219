import { Link } from 'react-router-dom';
import { Button } from '@windmill/react-ui';

interface Props {
  illustrationSrc: string;
  title: string;
  message: string;
  actionLabel?: string;
  actionRoute?: string;
}
const EmptyState: React.FC<Props> = (props) => {
  return (
    <section className="flex flex-col items-center">
      <img
        className="w-64 max-w-max"
        src={props.illustrationSrc}
        alt="crossroads illustration"
      />
      <h2>{props.title}</h2>
      <p className="mt-2">{props.message}</p>
      {props.actionRoute && (
        <Link to={props.actionRoute} className="no-underline mt-2">
          <Button>{props.actionLabel}</Button>
        </Link>
      )}
    </section>
  );
};

export default EmptyState;
